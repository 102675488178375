<template>
  <div
    class="container mt-8 position-relative"
    :class="fullWidthOnMobile ? 'px-0 px-md-3' : 'px-md-3'"
  >
    <div
      class="strapi-component__banner position-relative rounded"
      :class="bannerClass"
    >
      <strapi-image
        :image="component.backgroundImage"
        class="position-absolute top-0 start-0 w-100 h-100 rounded gp-shadow"
        as-background
      />
      <div
        v-if="component.promoText"
        class="position-relative p-4 p-md-8 row align-items-center justify-content-between"
      >
        <div class="col-12 col-md col-lg-6">
          <h2 class="mb-0">{{ component.title }}</h2>
          <p class="mt-4 strapi-component__banner-info">
            <strong>{{ component.text }}</strong>
          </p>
          <a
            v-for="button in component.buttons"
            :key="button.id"
            :href="button.link"
            :target="button.newTab ? '_blank' : '_self'"
            class="btn btn-primary btn-sm mt-4 px-8"
            :class="`btn-${button.type}`"
          >
            {{ button.text }}
          </a>
        </div>
        <div
          class="strapi-component__banner-bubble ms-auto mt-4 mt-md-0 rounded-circle"
        >
          <div
            class="strapi-component__banner-bubble__content rounded-circle text-uppercase text-center"
          >
            <span class="fw-bold fs-6">{{ component.promoText }}</span>
          </div>
        </div>
      </div>

      <div v-else class="h-100 py-8 py-lg-16 px-4 px-md-8 px-lg-20">
        <h3 class="mb-0 fs-4 text-uppercase">{{ component.title }}</h3>
        <h2 class="fs-1 strapi-component__banner-info">
          {{ component.text }}
        </h2>
        <div class="mt-4">
          <a
            v-for="button in component.buttons"
            :key="button.id"
            :href="button.link"
            :target="button.newTab ? '_blank' : '_self'"
            class="btn btn-primary btn-lg px-8 w-auto"
            :class="`btn-${button.type}`"
          >
            {{ button.text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { StrapiBanner } from '~/apollo/types/types';

export default defineComponent({
  name: 'StrapiBanner',
  props: {
    component: {
      type: Object as PropType<StrapiBanner>,
      required: true,
    },
    bannerClass: {
      type: String,
      default: undefined,
    },
    fullWidthOnMobile: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
@import 'gportal-theme/scss/colors';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

$bubble-size: 6.75rem;
$bubble-size-md: 9rem;

.strapi-component__banner {
  min-height: 12rem;

  &-bubble {
    width: $bubble-size;
    height: $bubble-size;
    padding: 0.2rem;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 30%);
    background-image: linear-gradient(
      125deg,
      $gp-midnight-3-a 16%,
      #3f314c 56%,
      $gp-midnight-gradient-b 82%
    );

    @include media-breakpoint-up(md) {
      width: $bubble-size-md;
      height: $bubble-size-md;
    }

    &__content {
      border: solid 1px #ddd;
      width: $bubble-size - 0.5rem;
      height: $bubble-size - 0.5rem;
      vertical-align: middle;
      display: table-cell;

      @include media-breakpoint-up(md) {
        width: $bubble-size-md - 0.5rem;
        height: $bubble-size-md - 0.5rem;
        letter-spacing: 2.4px;
      }
    }
  }

  &-info {
    letter-spacing: 1.5px;
  }
}
</style>
